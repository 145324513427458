import { zodResolver } from '@hookform/resolvers/zod'
import { logWarning } from '@qogita/logging/browser-logger'
import { Button } from '@qogita/ui/button'
import {
  Form,
  FormControl,
  FormFieldController,
  FormItem,
  FormLabel,
  FormMessage,
} from '@qogita/ui/form'
import { InlineNotification } from '@qogita/ui/inline-notification'
import { Input } from '@qogita/ui/input'
import { PasswordField, PasswordFieldInput } from '@qogita/ui/password-field'
import { requiredString } from '@qogita/validation/string'
import Head from 'next/head'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FormProvider, useForm } from 'react-hook-form'
import { NextAnchor } from 'src/deprecated/components/NextAnchor'
import { QogitaLogoWordmark } from 'src/deprecated/components/QogitaLogo'
import { RegisterLink } from 'src/deprecated/components/RegisterLink'
import {
  DeactivatedUserError,
  useLoginUser,
} from 'src/deprecated/hooks/api/useLoginUser'
import { useQueryParams } from 'src/deprecated/hooks/useQueryParams'
import {
  withAnonymousRoute,
  withServerLogger,
} from 'src/deprecated/lib/authentication/pageHelpers'
import { environment } from 'src/deprecated/lib/environment.mjs'
import { getFlags } from 'src/deprecated/lib/featureFlag'
import { useAnalytics } from 'src/deprecated/lib/report/AnalyticsProvider'
import { useTrackEvent } from 'src/deprecated/lib/report/tracking'
import { z } from 'zod'

const loginFormValidationSchema = z.object({
  email: requiredString('Please enter your email address.').email(
    'Please enter a valid email address.',
  ),
  password: requiredString('Please enter your password.'),
})

const querySchema = z.object({
  referrer: z.string().optional().default(''),
  redirectTo: z.string().default('/products'),
})

const LoginError = ({ error }: { error: Error | DeactivatedUserError }) => {
  if (error instanceof DeactivatedUserError) {
    return (
      <>
        Your account has been deactivated. Please contact{' '}
        <a
          className="hover:text-error-800 text-inherit underline"
          href="mailto:support@qogita.com"
          translate="no"
        >
          support@qogita.com
        </a>{' '}
        to reactivate your account.
      </>
    )
  }

  return <>{error.message}</>
}

const LoginPage = () => {
  const { query } = useRouter()
  const { referrer } = querySchema.parse(query)
  const loginUser = useLoginUser()
  const { identify } = useAnalytics()
  const { trackUserSignedIn } = useTrackEvent()
  const form = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: zodResolver(loginFormValidationSchema),
    mode: 'onBlur',
  })

  const [{ redirectTo }] = useQueryParams({ validationSchema: querySchema })
  const { replace } = useRouter()

  const baseUrl = new URL(environment.NEXT_PUBLIC_BASE_URL ?? '')
  const redirectUrl = new URL(redirectTo, baseUrl)

  const handleSubmit = form.handleSubmit(
    (data) => {
      if (loginUser.isPending) return

      loginUser.mutate(data, {
        onSuccess: ({ user }) => {
          identify(user)
          trackUserSignedIn({ email: user.email })
          if (redirectUrl.origin === baseUrl.origin) {
            replace(redirectTo)
          } else {
            replace('/products')
          }
        },
      })
    },
    (error) => logWarning('LoginForm client validation failure', error),
  )

  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-gray-100">
      <Head>
        <title>Login</title>
        <meta name="description" content="" />
      </Head>
      <strong>
        <Link href="/">
          <QogitaLogoWordmark scheme="black" className="h-[2.5rem] w-full" />
        </Link>
      </strong>
      <h1 className="mb-12 mt-6 text-center text-3xl sm:mt-12">
        Login to your account
      </h1>
      <div className="w-full max-w-md">
        {referrer === 'register' ? (
          <InlineNotification variant="success" className="mb-6">
            <InlineNotification.Description>
              Your account was created successfully, and you can now login.
              Please check your email for instructions on how to verify.
            </InlineNotification.Description>
          </InlineNotification>
        ) : null}
        {referrer === 'reset-password' ? (
          <InlineNotification variant="success" className="mb-6">
            <InlineNotification.Description>
              Your password was reset successfully, and you can now login.
            </InlineNotification.Description>
          </InlineNotification>
        ) : null}
        <FormProvider {...form}>
          <Form
            onSubmit={handleSubmit}
            className="mb-6 flex flex-col bg-white p-6 shadow min-[448px]:rounded-lg"
          >
            <FormFieldController
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="mb-3">
                  <FormLabel>Email address</FormLabel>
                  <FormControl>
                    <Input {...field} type="email" autoComplete="username" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormFieldController
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem className="mb-3">
                  <FormLabel>Password</FormLabel>
                  <PasswordField>
                    <FormControl>
                      <PasswordFieldInput
                        {...field}
                        autoComplete="current-password"
                      />
                    </FormControl>
                  </PasswordField>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="mb-3 self-end">
              <NextAnchor
                color="primary"
                appearance="text"
                size="medium"
                href="/reset-password/"
                className="whitespace-nowrap font-medium"
              >
                Forgot password?
              </NextAnchor>
            </div>

            {loginUser.isError ? (
              <InlineNotification variant="error" className="mb-3">
                <InlineNotification.Icon />
                <InlineNotification.Content>
                  <InlineNotification.Title className="font-bold">
                    There was a problem
                  </InlineNotification.Title>
                  <InlineNotification.Description>
                    <LoginError error={loginUser.error} />
                  </InlineNotification.Description>
                </InlineNotification.Content>
              </InlineNotification>
            ) : null}
            <Button
              type="submit"
              className="mb-4 w-full"
              isLoading={loginUser.isPending}
            >
              <Button.Loader>Login</Button.Loader>
            </Button>
            <div className="text-center">
              New to Qogita?{' '}
              <RegisterLink
                color="primary"
                size="inline"
                className="whitespace-nowrap"
              >
                Get started
              </RegisterLink>
            </div>
          </Form>
        </FormProvider>
      </div>
    </div>
  )
}

export const getServerSideProps = withServerLogger(
  withAnonymousRoute(async () => {
    const featureFlagState = await getFlags()
    return { props: { featureFlagState } }
  }),
)

export default LoginPage
