import { useCanaryClient } from '@qogita/canary-client/provider'
import type { ObtainToken, ObtainTokenRequest } from '@qogita/canary-types'
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { getUserQueries } from 'src/deprecated/api/user-queries'
import { isDisallowedCurrency } from 'src/deprecated/lib/currency'
import { DisallowedUserCurrencyError } from 'src/deprecated/lib/custom-errors'

type PostAuthLogin = (params: ObtainTokenRequest) => Promise<ObtainToken>

type ErrorResponse = {
  message: string
  code: string
}
export class DeactivatedUserError extends Error {
  constructor(message: string) {
    super(message)
  }
  name = 'DeactivatedUserError'
}

const ERROR_MESSAGE_CATCH_ALL =
  'There was an error logging in. Please try again or speak to a member of our team if the issue persists.'

const postAuthLogin: PostAuthLogin = async ({ email, password }) => {
  const response = await fetch(`/api/auth/login/`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      password,
    }),
  })

  if (response.ok) {
    const obtainToken: ObtainToken = await response.json()

    if (isDisallowedCurrency(obtainToken.user.currency)) {
      throw new DisallowedUserCurrencyError(
        "You can't buy via Qogita with your currency. Create a new account to buy with a different currency.",
        {
          currency: obtainToken.user.currency,
        },
      )
    }
    return obtainToken
  }

  if (response.status === 401) {
    throw new Error('The email address or password you entered is invalid.')
  }

  if (response.status === 403) {
    // TODO: should we validate this with zod?
    const { code }: ErrorResponse = await response.json()
    if (code === 'deactivated') {
      throw new DeactivatedUserError(
        'Your account has been deactivated. Please contact support@qogita.com to reactivate your account.',
      )
    }
  }

  throw new Error(ERROR_MESSAGE_CATCH_ALL)
}

export const useLoginUser = (): UseMutationResult<
  ObtainToken,
  Error | DeactivatedUserError,
  ObtainTokenRequest
> => {
  const queryClient = useQueryClient()
  const canaryClient = useCanaryClient()
  const userQueries = getUserQueries(canaryClient)
  return useMutation({
    mutationFn: ({ email, password }) => postAuthLogin({ email, password }),
    onSuccess: ({ user }) => {
      if (isDisallowedCurrency(user.currency)) {
        throw new DisallowedUserCurrencyError(
          'Your currency is not supported. Please use another account or contact support.',
          {
            currency: user.currency,
          },
        )
      }

      queryClient.setQueryData(userQueries.detail().queryKey, user)
    },
  })
}
